// blog data

export const Blogs = [
    {
        id:1,
        name:"Trace the Path: Distributed Tracing with Zipkin in Microservices-1",
        tags:["Microservices","Distribute Tracer","Zipkin","Java"],
        date:"31 May, 2024",
        imgSrc:"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*I61IrtPF9joECTUW7YJm8g.png",
        link:"https://medium.com/@lpramithamj/trace-the-path-distributed-tracing-with-zipkin-in-microservices-1-5096aaade124"
    },
      {
        id:2,
        name:"Why do we need to use Circuit Bracker Pattern inside Microservices?",
        tags:["Microservices","Circuit Bracker","Design patterns","Java"],
        date:"7 April, 2024",
        imgSrc:"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*6vQmHRN60fWX5pgwg3GGJw.png",
        link:"https://medium.com/@lpramithamj/why-do-we-need-to-use-circuit-bracker-pattern-inside-microservices-254a7a2b48d7"
    },
       {
        id:3,
        name:"[Solved] Cross-Site Request Forgery (CSRF) Attacks with Spring Security",
        tags:["Spring","CSRF Attack","CSRF protection","Java","SpringSecurity"],
        date:"8 March, 2024",
        imgSrc:"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*QtZ41v540D07G_vGwhMWmg.png",
        link:"https://medium.com/@lpramithamj/solved-cross-site-request-forgery-csrf-attacks-with-spring-security-0cf0935cc1be"
    },
    
  
    {
        id:4,
        name:"Synchronizing Spring: The Artistry of Data Transfer Objects (DTOs) for Effortless Communication in Your Boot Applications",
        tags:["Java","SpringBoot","DTO","Spring","DesignPatterns"],
        date:"17 February, 2024",
        imgSrc:"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*uyg37yWxIJhutMtIGKVqmw.png",
        link:"https://medium.com/@lpramithamj/monolithic-vs-soa-vs-microservices-architecture-a-java-perspective-6d3d9fb26ac7"
    },
    {
        id:5,
        name:"Monolithic vs. SOA vs. Microservices Architecture: A Java Perspective",
        tags:["Java","Monolithic","SOA","Microservices","Architecture"],
        date:"8 February, 2024",
        imgSrc:"https://miro.medium.com/v2/resize:fit:1400/format:webp/0*GqQJd_ZaRPRSJs38.jpg",
        link:"https://medium.com/@lpramithamj/monolithic-vs-soa-vs-microservices-architecture-a-java-perspective-6d3d9fb26ac7"
    },
    {
        id:6,
        name:"Art of Clean Code: Understanding the SOLID Principles in Java",
        tags:["Java","OOD","SOLID","DesignPatterns","CleanCode"],
        date:"1 February, 2024",
        imgSrc:"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*r7ghEEpTH0EIwrxtJRUfbg.jpeg",
        link:"https://medium.com/@lpramithamj/art-of-clean-code-understanding-the-solid-principles-in-java-dcedb42a3f07"
    },
    {
        id:7,
        name:"Mastering Spring Data JPA Testing: A Comprehensive Guide to Repository Layer Testing.",
        tags:["Java","Spring","Testing","JPA","Repository","Mockito","JUnit"],
        date:"28 December, 2023",
        imgSrc:"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*nxMm4dVLkS7mS0WpNpZ4Ug.png",
        link:"https://medium.com/@lpramithamj/mastering-spring-data-jpa-testing-a-comprehensive-guide-to-repository-layer-testing-f7b1863afa95"
    },
    {
        id:8,
        name:"Microservices Unleashed: Elevating Software Development",
        tags:["Java","Spring","Microservices","Architecture","DesignPatterns"],
        date:"11 December, 2023",
        imgSrc:"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*sinHc_6xQk5NQXEIo49F2Q.jpeg",
        link:"https://medium.com/@lpramithamj/microservices-unleashed-elevating-software-development-ea1004e01949"

    },
     {
        id:9,
        name:"The Magic of Git & GitHub",
        tags:["Git","GitHub","VersionControl","SoftwareDevelopment"],
        date:"28 February, 2024",
        imgSrc:"https://miro.medium.com/v2/resize:fit:1400/format:webp/0*BbGjKaBZDKqc0E-6.jpg",
        link:"https://medium.com/@lpramithamj/the-magic-of-git-github-4d3e183a808d"
    },
   
] 